import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import Analytics from "react-router-ga";
import "./App.css";

const App = () => {
	return (
		<Router>
			<Analytics id="UA-175493208-1">
				<Switch>
					<Route path="/" exact component={HomePage} />
					<Route path="/about" component={AboutPage} />
					<Route path="/privacy" component={PrivacyPage} />
					<Route path="/:brand" component={ProductPage} />
				</Switch>
			</Analytics>
		</Router>
	);
};

const HomePage = () => {
	return (
		<main>
			<h1>
				<div className="dealDetails">
					<a href="https://vaped4u.com/?utm_source=topjuice&utm_medium=passthrough&utm_campaign=homepage">
						<img className="image" src={`/images/logo-100x100.png`} alt={`Top Juice Deals Logo`} />
					</a>
				</div>
			</h1>
			<div className="about">
				<Link to="/about" style={{ paddingRight: 2 }}>
					About
				</Link>
				<Link to="/privacy">Privacy</Link>
			</div>
		</main>
	);
};

const ProductPage = (props) => {
	const [hasLoaded, setHasLoaded] = React.useState(false);

	const detailsFile = process.env.PUBLIC_URL + "/deals.json";
	const [product, setProduct] = React.useState({});

	React.useEffect(() => {
		fetch(detailsFile)
			.then((res) => res.json())
			.then((result) => {
				let foundProduct = result.filter((deal) => deal.product === props.match.params.brand);
				setProduct(foundProduct[0]);
				setHasLoaded(true);
			});
	}, [detailsFile, props.match.params.brand]);

	const getImage = () =>
		product ? (
			<img className="image" src={`/images/${product?.logo}`} alt={getHeader()} />
		) : (
			<img className="image" src={`/images/logo-100x100.png`} alt={`Top Juice Deals Logo`} />
		);
	const getHeader = () => (props.match.params.brand ? props.match.params.brand.replace("-", " ") : null);

	const getDescription = () => {
		return {
			__html: product ? product.deal : `Find the best deals on ${props.match.params.brand.replace("-", " ")}`,
		};
	};
	const getLink = () =>
		product
			? product.url + `?utm_source=topjuice&utm_medium=passthrough&utm_campaign=${props.match.params.brand}`
			: `https://vaped4u.com/?s=${props.match.params.brand.replace(
					"-",
					"+",
			  )}&post_type=product&dgwt_wcas=1&utm_source=topjuice&utm_medium=passthrough&utm_campaign=${props.match.params.brand}`;

	const meta = {
		title: `${getHeader().toUpperCase()} | TopJuice Deals - Best deals online!`,
		description: product ? `${product?.deal?.replace(/(<([^>]+)>)/gi, "")}` : `Find the best deals on ${props.match.params.brand.replace("-", " ")}`,
		canonical: `https://topjuicedeals.co.uk/${props.match.params.brand}`,
		meta: {
			charset: "utf-8",
			name: {
				keywords: `liquid, deals, ${props.match.params.brand}, best price, online, order, ecommerce`,
			},
		},
	};

	return hasLoaded ? (
		<DocumentMeta {...meta}>
			<main>
				<div className="spacer"></div>

				<div className="dealDetails">
					{getImage()}
					<h1>{getHeader()}</h1>
					<p className="desc" dangerouslySetInnerHTML={getDescription()}></p>
					<div className="ageVerify">
						<p className="ageStatement">Please confirm you are over 18 years old..</p>
						<a href="https://google.com">
							<button className="notOfAge">Under 18</button>
						</a>
						<a href={getLink()}>
							<button className="isOfAge">Over 18</button>
						</a>
					</div>
				</div>
				<div className="spacer"></div>
				<div className="about">
					<Link to="/about" style={{ paddingRight: 2 }}>
						About
					</Link>
					<Link to="/privacy">Privacy</Link>
				</div>
			</main>
		</DocumentMeta>
	) : (
		<main>
			<h1>
				<div className="dealDetails">Loading...</div>
			</h1>
		</main>
	);
};

const AboutPage = () => {
	return (
		<main>
			<h1>
				<div className="dealDetails">About us...</div>
			</h1>
			<p style={{ color: "white" }}>
				TopJuiceDeals is committed to getting you the best deals on your juices. You wont find cheaper prices or a wider range of stock than what our
				partners provide!
			</p>
		</main>
	);
};

const PrivacyPage = () => {
	return (
		<main>
			<h1>
				<div className="dealDetails">Privacy...</div>
			</h1>
			<p style={{ color: "white" }}>
				TopJuiceDeals complies with the General Data Protection Regulation (GDPR)
				<br />
				Your privacy is important to us. This policy to learn about the personal data that we collect from you and how we use it. Get in touch if you
				have any queries.
				<br />
				<br />
				This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from
				https://topjuicedeals.co.uk (the &ldquo;Site&rdquo;).
				<br />
				<br />
				Personal Information we collect
				<br />
				<br />
				When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP
				address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information
				about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you
				interact with the Site. We refer to this automatically-collected information as &ldquo;Device Information.&rdquo; This informed is stored on
				secure servers at all times with all data transactions between the server and clients conducted over secure web protocols. <br />
				We collect Device Information using the following technologies:
				<br />
				<br />
				&ldquo;Cookies&rdquo; are data files that are placed on your device or computer and often include an anonymous unique identifier. For more
				information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
				<br />
				<br />
				&ldquo;Log files&rdquo; track actions occurring on the Site, and collect data including your IP address, browser type, Internet service
				provider, referring/exit pages, and date/time stamps. <br />
				<br />
				&ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo; and &ldquo;pixels&rdquo; are electronic files used to record information about how you browse the
				Site.
				<br />
				<br />
				Sharing your personal information
				<br />
				<br />
				We share your Personal Information with third parties to help us use your Personal Information, as described above. These third parties are
				listed below; We have partner companies which we may share information to as part of order processing. We also use Google Analytics to help us
				understand how our customers use the Site &ndash; you can read more about how Google uses your Personal Information here:
				https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
				Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or
				other lawful requests for information we receive, or to otherwise protect our rights.
				<br />
				<br />
				Do not track
				<br />
				<br />
				Please note that we do not alter our Site&rsquo;s data collection and use practices when we see a Do Not Track signal from your browser. <br />
				<br />
				Your rights
				<br />
				<br />
				If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be
				corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. Additionally,
				if you are a European resident we note that we are processing your information in order to fulfil contracts we might have with you (for example
				if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that
				your information will be transferred outside of Europe, including to Canada and the United States.
				<br />
				<br />
				Data retention
				<br />
				<br />
				When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this
				information.
				<br />
				<br />
				Minors
				<br />
				<br />
				The Site is not intended for individuals under the age of 18.
				<br />
				<br />
				Changes
				<br />
				<br />
				We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal
				or regulatory reasons.
				<br />
				<br />
				Terms of Service
				<br />
				<br />
				Specific terms relating to accessing our website and the purchase of our products can be found in our Terms of Service policy. Please read
				before using our service.
				<br />
				<br />
				Contact Us
				<br />
				<br />
				For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at
				info@topjuicedeals.co.uk or by mail using the details provided below:
				<br />
				<br />
				TopJuiceDeals
				<br />
				Basement Flat
				<br />
				198 Nithsdale Road
				<br />
				Glasgow
				<br />
				G41 5EU
				<br />
			</p>
		</main>
	);
};

export default App;
